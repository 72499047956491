<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseVerify">
		<div class="baseVerify-content">
			<div class="activityVerify-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="faculty" label="学院班级">
					</el-table-column>
					<el-table-column prop="code" label="证明文件编号">
					</el-table-column>
					<el-table-column prop="createTime" label="生成时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('downLoad', scope)">下载</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listManHourProve
	} from "@/api/electronic";
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
			};
		},
		mounted() {
			this.getManHourProveList()
		},
		methods: {
			actionFn(type, scope) {
				switch (type) {
					case "downLoad":
						window.open(scope.row.accessUrl)
						break;
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getManHourProveList();
			},
			getManHourProveList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				listManHourProve(params).then((res) => {
					if (res.code == 0) {
						if (res.data.length > 0) {
							res.data.forEach(item => {
								if(item.fileName) {
									let pointIndex = item.fileName.lastIndexOf(".")
									let subStr = item.fileName.substring(0, pointIndex)
									console.log("pointIndex", pointIndex, subStr)
									this.$set(item, "fileNum", subStr)
								} else {
									this.$set(item, "fileNum", "")
								}
							})
							this.tableData = res.data
							this.totalCount = res.page.totalCount * 1
						}
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	.baseVerify {
		.baseVerify-content {
			padding: 20px 20px;
		}
	}
</style>
